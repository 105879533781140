const BASE_URL = 'https://parked.ceelo-development.com/jav/api';

const EmailService = {
  addEmailToParkedDomainVisitors: async (email, domain) => {
    try {
      const response = await fetch(`${BASE_URL}/add-email-to-parked-domain-visitors`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded', // Change content type
        },
        body: new URLSearchParams({ email, domain }), // Format body as URL-encoded
        mode: 'cors',
      });

      if (!response.ok) {
        throw new Error('Failed to submit email');
      }

      // Handle non-JSON response
      const text = await response.text();
      return text;
    } catch (error) {
      console.error('Network error:', error);
      throw error;
    }
  },
};

export default EmailService;