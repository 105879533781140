import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { Cylinder } from '@react-three/drei';
import { TextureLoader } from 'three';
import { useLoader } from '@react-three/fiber';

function RotatingCube({ texture }) { // Accept texture as prop
  const meshRef = useRef();
  const loadedTexture = useLoader(TextureLoader, texture); // Use dynamic texture

  useFrame((state, delta) => {
    meshRef.current.rotation.x += delta;
    meshRef.current.rotation.y += delta * 0.5;
  });

  const dotPositions = [
    // Face 1 (front) - 1 dot
    [[0, 0, 0.5]],
    // Face 2 (back) - 2 dots
    [[-0.3, 0.3, -0.5], [0.3, -0.3, -0.5]],
    // Face 3 (top) - 3 dots
    [[-0.3, 0.5, 0.3], [0, 0.5, 0], [0.3, 0.5, -0.3]],
    // Face 4 (bottom) - 4 dots
    [[-0.3, -0.5, -0.3], [0.3, -0.5, 0.3], [-0.3, -0.5, 0.3], [0.3, -0.5, -0.3]],
    // Face 5 (left) - 5 dots
    [[-0.5, 0.3, 0.3], [-0.5, 0, 0], [-0.5, -0.3, -0.3], [-0.5, 0.3, -0.3], [-0.5, -0.3, 0.3]],
    // Face 6 (right) - 6 dots
    [[0.5, 0.3, -0.3], [0.5, 0.3, 0.3], [0.5, -0.3, -0.3], [0.5, -0.3, 0.3], [0.5, 0, -0.3], [0.5, 0, 0.3]]
  ];

  const getRotation = (pos) => {
    if (pos[2] === 0.5 || pos[2] === -0.5) {
      // Front and back faces
      return [Math.PI / 2, 0, 0];
    } else if (pos[0] === 0.5 || pos[0] === -0.5) {
      // Left and right faces
      return [0, 0, Math.PI / 2];
    } else {
      // Top and bottom faces
      return [0, 0, 0];
    }
  };

  const adjustPosition = (pos) => {
    const offset = 0.02; // Increased offset from 0.025 to 0.03
    if (pos[2] === 0.5) {
      return [pos[0], pos[1], pos[2] - offset];
    } else if (pos[2] === -0.5) {
      return [pos[0], pos[1], pos[2] + offset];
    } else if (pos[0] === 0.5) {
      return [pos[0] - offset, pos[1], pos[2]];
    } else if (pos[0] === -0.5) {
      return [pos[0] + offset, pos[1], pos[2]];
    } else if (pos[1] === 0.5) {
      return [pos[0], pos[1] - offset, pos[2]];
    } else if (pos[1] === -0.5) {
      return [pos[0], pos[1] + offset, pos[2]];
    } else {
      return pos;
    }
  };

  return (
    <mesh ref={meshRef} castShadow receiveShadow>
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial map={loadedTexture} /> {/* Use the dynamic texture */}
      {dotPositions.flat().map((pos, index) => (
        <Cylinder
          key={index}
          args={[0.15, 0.15, 0.05, 32]}
          position={adjustPosition(pos)}
          rotation={getRotation(pos)}
        >
          <meshStandardMaterial color="black" />
        </Cylinder>
      ))}
    </mesh>
  );
}

export default RotatingCube;
