import React, { useState } from 'react';
import './App.css';
import EmailCollector from './components/EmailCollector';
import Header from './components/Header';
import Footer from './components/Footer';
import { Canvas } from '@react-three/fiber';
import { Stars } from '@react-three/drei';
import RotatingCube from './components/RotatingCube';

function App() {
  const [texture, setTexture] = useState('white-wall.jpg'); // Ensure correct path

  const handleEmailSubmit = () => {
    setTexture('wall.jpg'); // Ensure correct path
  };

  return (
    <div className="App">
      <div className="content">
        <main>
          <div className="canvas-container">
            <Canvas shadows camera={{ position: [0, 0, 5] }}>
              <Stars />
              <ambientLight intensity={0.5} />
              <pointLight position={[10, 10, 10]} castShadow />
              <RotatingCube texture={texture} /> {/* Pass texture as prop */}
            </Canvas>
          </div>
          <EmailCollector onEmailSubmit={handleEmailSubmit} /> {/* Pass callback */}
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default App;
