import React, { useState } from 'react';
import EmailService from '../service/EmailService';

function EmailCollector({ onEmailSubmit }) { // Accept the callback function as a prop
  const [email, setEmail] = useState('');
  const [domain] = useState('ceelo.com'); 
  const [message, setMessage] = useState(''); // State for feedback message

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await EmailService.addEmailToParkedDomainVisitors(email, domain);
      console.log('Email submitted:', email);
      setMessage('Email submitted successfully!');
      setEmail('');
      if (onEmailSubmit) onEmailSubmit(); // Call the callback function
    } catch (error) {
      console.error('Error submitting email:', error);
      setMessage('Error submitting email. Please try again.');
    }
  };

  return (
    <div className="email-collector">
      <h2>Profitable Products Owned by Creators and Developers</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
        />
        <button type="submit">Subscribe</button>
      </form>
      {message && <p>{message}</p>} {/* Display feedback message */}
    </div>
  );
}

export default EmailCollector;
